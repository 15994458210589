const LITERALS = {
  MENU: {
    MANAGER_COOKIES: 'Gérer les Cookies',
    PRIVACY_POLICY: 'Politique de confidentialité',
    FAQ: 'FAQ',
  },
  BUTTON: {
    LEARN_MORE: 'En savoir plus',
    SAVE: 'Enregistrer',
    ACCEPT: "J'accepte",
    DECLINE: 'Déclin',
    ACCEPT_ALL: 'Accepter tout',
    DECLINE_ALL: 'Refuser tout',
    HERE: 'ici',
    PRIVACY_POLICY: 'Politique de confidentialité',
  },
  CONSENTS: {
    BANNER: {
      TITLE: 'Politique de confidentialité',
      DESCRIPTION:
        'Nous collectons et traitons vos informations personnelles dans le but suivant. Pour en savoir plus, merci de lire notre',
    },
    MODAL: {
      TITLE: 'Les informations que nous collectons',
      DESCRIPTION:
        'Ici, vous pouvez voir et personnaliser les informations que nous collectons sur vous. Pour en savoir plus, merci de lire notre',
      COOKIES: {
        OPT_OUT: 'Opt-out',
        NECESSARY: {
          TITLE: 'Cookies strictement nécessaires',
          DESCRIPTION:
            'Les cookies strictement nécessaires sont activés par défaut mais peuvent être désactivés dans les paramètres de votre navigateur.',
          APPS: {
            OWN: {
              TITLE: 'Privacy Manager',
              REQUIRED: 'toujours requis',
              DESCRIPTION:
                'Enregistrez cette configuration pour des visites récurrentes.',
            },
          },
        },
        PERFORMANCE: {
          TITLE: 'Cookies de performance',
          DESCRIPTION:
            'Cela permet de nous assurer que le site Web fonctionne correctement et de corriger les éventuelles erreurs. Et il nous permet de tester différentes possibilités.',
          APPS: {
            GOOGLE_TAG_MANAGER: {
              TITLE: 'Google Tag Manager',
              DESCRIPTION:
                'Cela nous permet de comprendre comment les gens utilisent nos services afin de les améliorer.',
            },
            YOUTUBE: {
              TITLE: 'Youtube',
              DESCRIPTION:
                'Cookie défini par YouTube pour suivre les vues des vidéos intégrées sur les sites Skouting.',
            },
          },
        },
      },
    },
  },
  SKTOKES: {
    TABLE: {
      TITLE: 'Tableau des transactions',
      HEAD: {
        DATE: 'DATE',
        MINUTES: 'MINUTES',
        SERVICES: 'SERVICES',
      },
    },
  },
  LICENSE: {
    BUY: 'Acheter',
    FILTER: 'Filtres',
    STATUS: {
      ALL: 'Toutes',
      ACTIVE: 'Actif',
      INACTIVE: 'Inactif',
      EXPIRED: 'Expiré',
      REVOKED: 'Révoqué',
      ACTIVE_REVOKED: 'Actif révoqué',
      INACTIVE_REVOKED: 'Inactif révoqué',
      EXPIRED_REVOKED: 'Expiré révoqué',
    },
    TABLE: {
      HEAD: {
        LICENSE_AVAILABLE: 'Licence disponible',
        LICENSE_TYPE: 'Type',
        CREATE_DATE: 'Création',
        ACTIVATION_DATE: 'Activation',
        VALID_UNTIL: 'Validité',
        STATUS: 'Statut',
        ACTIONS: 'Actions',
      },
    },
    ACTION: {
      COPIED_CLIPBOARD: 'Licence copiée dans le presse-papiers',
    },
    MESSAGE: {
      NOT_ACTIVATED: 'Non activé',
    },
  },
  ENGARDE_SERVICES: {
    ORGANISM: 'Organisme',
    EVENT: 'Événement',
    COMPETITION: 'Concours',
    PHASE: 'Phase',
    PHASE_OPTIONS: {
      NOT_SPECIFIED: 'Non spécifié',
      POULES: 'Poules',
      TABLEAU: 'Tableau',
    },
  },
  SUBSCRIPTION: {
    PAYMENT_MODE: {
      MONTHLY: 'Par mois / Paiement mensuel',
      ANNUAL: 'Annuel',
    },
    BUTTON: {
      COUPON_CODE: 'Code coupon',
      COMING_SOON: 'Bientôt disponible',
      ALREADY_PURCHASED: 'Déjà acheté',
      INCLUDED: 'Inclus',
      BUY_NOW: 'Acheter maintenant',
    },
    PLANS: {
      BASIC: {
        TITLE: 'Basique',
        BENEFIT_1: 'Regardez, commentez et partagez',
        BENEFIT_2: 'Accéder à la base de données vidéo',
        BENEFIT_3: 'Créer des Analyse Vidéo',
        BENEFIT_4: 'Accéder à la barre de contrôle vidéo',
        BENEFIT_5: 'Créer des groupes',
        BENEFIT_6: 'Statistiques avancées',
        BENEFIT_7: '3.000 SK Tokens par mois / 20.000 Tokens par an',
        BENEFIT_8: '2 % de réduction sur les tokens',
      },
      EXPERT: {
        TITLE: 'Expert',
        BENEFIT_1: 'Tous les avantages de la licence Premium +',
        BENEFIT_2: 'Licence Skouting Creator',
        BENEFIT_3: '100 GB de stockage',
        BENEFIT_4: '35.000 SK Tokens par mois / 150.000 SK Tokens par an',
        BENEFIT_5: '5% de réduction sur les tokens',
      },
      PREMIUM: {
        TITLE: 'Prime',
        BENEFIT_1: 'Tous les avantages de Basic+',
        BENEFIT_2: '10 GB de stockage',
        BENEFIT_3: 'In-depth reports and statistics',
        BENEFIT_4: "Accès à l'analyse de l'IA",
        BENEFIT_5: '2 Analyse en même temps',
        BENEFIT_6: 'Skouting Creator License',
        BENEFIT_7: '7.000 SK Tokens par mois / 60.000 SK Tokens par an',
        BENEFIT_8: '3% de réduction sur les tokens',
        BENEFIT_9: 'Tous les avantages à partir de basic +',
      },
    },
  },
  GROUPS: {
    ERROR: {
      DUPLICATE_EMAIL: 'Il y a des membres avec la même adresse e-mail',
    },
    ACTION: {
      DELETE_GROUP:
        'Êtes-vous sûr de vouloir supprimer ce groupe ? Cette action ne peut pas être annulée.',
      DELETE_MEMBER:
        'Êtes-vous sûr de vouloir supprimer cet invité du groupe ? Cette action ne peut pas être annulée',
    },
  },
  USER: {
    PROFILE: {
      TAB: {
        MEMBERSHIPS: 'Affiliations',
      },
      MEMBERSHIP: {
        TABLE: {
          FIRST_NAME: 'Prénom',
          LAST_NAME: 'Nom',
          GENDER: 'Sexe',
          WEAPONS: 'Armes',
          LATERALITY: 'Latéralité',
          COUNTRY: 'Pays',
          BIRTHDAY: 'Naissance',
          ORGANISM: 'Organisme',
          ORGANISM_TYPE: 'Type',
        },
        DETAILS: {
          LICENSE: 'Licence',
          DUE_DATE: 'Expiration',
          STATUS: 'Statut',
          ACTIVE: 'Actif',
          INACTIVE: 'Inactif',
        },
      },
    },
  },
};

const lang = {
  ...LITERALS,
  'Contact us': 'Contactez-nous',
  'Web develop by': 'Développement web par',
  Yes: 'Oui',
  No: 'Non',
  'Without results': 'Sans résultats',
  'Order by': 'trier par',
  Newest: 'Plus récent',
  Oldest: 'Plus ancien',
  'Please input your email!': 'Please enter your email',
  'You must enter a valid Email address!':
    'Vous devez entrer une adresse mail valide',
  Email: 'Adresse email',
  'Reset Password': 'Réinitialiser le mot de passe',
  Search: 'Rechercher',
  Ok: 'Ok',
  'Video Replay FILE': 'Fichier vidéo replay',
  'Please input your Password!': 'Mot de passe requis',
  Password: 'Mot de passe',
  'Forgot Password?': 'Mod de passe oublié?',
  'Pre-Register': 'Pré-enregistrement',
  'Log In': 'Connexion',
  Create: 'Créer',
  'Premium Account': 'Compte Premium',
  'Trial Version': "Version d'essai",
  'TO SHARE OR WATCH SHARED VIDEOS YOU NEED TO BE PREMIUM':
    'CRÉER UN COMPTE PREMIUM AFIN DE PARTAGER OU DE REGARDER DES VIDÉOS PARTAGÉES',
  'WHAT IS PREMIUM?': 'CE QUI EST PREMIUM?',
  Notifications: 'Notifications',
  ErrorSuccessPaypal:
    "Une erreur s'est produite lors du traitement du paiement avec PayPal",
  SuccessPaypal: 'Le paiement a été traité correctement',
  ErrorPayPaypal: "Le paiement n'a pas été effectué, veuillez réessayer",
  CancelPayPaypal: "Paiement non effectué, l'utilisateur a annulé le processus",
  Premium: 'Premium',
  'Watch, comment and share': 'Regarder, commenter et partager',
  'Access video database': 'Accéder à la base de données vidéo',
  'Create Skoutings': 'Créer des Analyses Vidéo',
  'Access video control bar': 'Accéder à la barre de contrôle vidéo',
  'Advanced statistics': 'Statistiques avancées',
  '10GB storage (coming soon)': '10Go de stockage (à venir)',
  'In-depth reports and statistics': 'Rapports et statistiques approfondis',
  'Access to AI analysis': "Accès à l'analyse de l'IA",
  '2 Analysis at the same time': '2 Analyse en même temps',
  'Special Video Analysis Software': "Logiciel spécial d'analyse vidéo",
  'All benefits from Premium +': 'Tous les avantages de Premium +',
  '1 GB storage': '1 Go de stockage',
  '10 Analysis at the same time': '10 Analyse en même temps',
  'Special Algorithms': 'Algorithmes spéciaux',
  'Faster analysis': 'Analyse plus rapide',
  'Free Trial': 'Essai gratuit',
  'Buy Now': 'Acheter maintenant',
  'My Profile': 'Mon Profil',
  'Change Password': 'Modifier le mot de passe',
  'Legal warning': 'Mentions Légales',
  'Log Out': 'Déconnecter',
  'Are you sure? all the unsaved changes will be lost':
    'Vous êtes sûr ? Tous les changements non sauvegardés seront perdus',
  Exit: 'Sortir',
  Save: 'Enregistrer',
  Public: 'Public',
  Private: 'Privé',
  'This field is required!': 'Ce champ est requis !',
  Name: 'Nom',
  Group: 'Groupe',
  'Time Controls': 'Contrôles des temps',
  'Playback Rate': 'Fréquence de Playback',
  Simple: 'Simple',
  Off: 'Off',
  On: 'On',
  Cover: 'Image',
  Category: 'Catégorie',
  Tags: 'Etiquettes',
  'Created At': 'Créé Par',
  Visibility: 'Visibilité',
  "You can't add a duplicated tag unless you add a new protagonist first":
    'Impossible d’ajouter une étiquette déjà existante sauf si un nouveau protagoniste est créé ',
  'only two tags/protagonist can be concatenated':
    'Uniquement deux étiquettes/protagonistes peuvent être concaténées',
  'You have to create an action before concatenating tags':
    'Une action doit être créée avant d’enchaîner des étiquettes',
  'You have to add a tag or protagonist first':
    'Une étiquette ou un protagoniste doit être créé en premier lieu',
  Country: 'Pays',
  'First Name': 'Prénom',
  'Last Name': 'Nom de famille',
  'Password must have at least 8 character and contain at least one number':
    'Le mot de passe doit comporter au moins 8 caractères et contenir au moins 1 chiffre',
  'Password Confirmation': 'Confirmation mot de passe',
  'Password must match!': 'Le mot de passe doit correspondre !',
  'Please enter a invitation code':
    "Veuillez saisir un code d'invitation ou laissez-le vide.",
  'Invitation Code': "Code d'invitation (non obligatoire)",
  'Invitation code is not valid': "Le code d'invitation n'est pas valide",
  'Already have an account?': 'Vous avez déjà un compte?',
  'Create a skouting account': 'Veuillez créer un compte de skouting',
  'Login skouting': 'Utilisateur de skouting',
  'Go Back': 'Retour',
  Sports: 'Sports',
  Others: 'Autres',
  Asc: 'Asc',
  Desc: 'Desc',
  'All Sports': 'Tous les sports',
  Videos: 'Vidéos',
  'My Scoutings': 'Mes Skoutings',
  Scoutings: 'Skoutings',
  'Scoutings Shared with me': 'Skoutings partagés avec moi',
  Hi: 'Bonjour',
  Total: 'Total',
  'Your payment was not approved': 'Le paiement a été refusé',
  'Please try again with another card or a different payment method':
    'Veuillez essayer avec une autre carte ou un autre moyen de paiement',
  'Your account has been activated': 'Votre compte a été activé',
  'You can now access all the features of a Premium user':
    "Vous pouvez désormais accéder à toutes les fonctionnalités d'un utilisateur Premium",
  'Visit our FAQs for more information':
    "Visitez notre FAQ pour plus d'informations",
  'Video Replay BASIC': 'Replay Vidéo BASIC',
  Monthly: 'Mensuel',
  Annual: 'Annuel',
  Permanent: 'Permanent',
  'Software license': 'Licence logicielle',
  'Already had a free license': 'Avait déjà une licence gratuite',
  Continue: 'Continuer',
  '13.99 € / month * 1 month': '13.99 € / mois * 1 mois',
  '4.99 € / month * 12 months (You save 108 €)':
    '4,99 € / mois * 12 mois (108 € en moins)',
  'Redeem a code': 'Utiliser un coupon',
  'Enter your coupon code below':
    'Veuillez insérer le code de votre coupon ci-dessous',
  Redeem: 'Utiliser',
  'Free trial (15 Days)': 'Période d’essai (15 jours)',
  'Free trial': 'Période d’essai',
  Home: 'Page d’accueil',
  'Do you want to remove this action?': 'Voulez-vous supprimer cette action?',
  'No comments yet!': 'Pas encore de commentaires !',
  'Write a comment': 'Écrire un commentaire',
  'Internal Share': 'Partage interne',
  Actions: 'Actions',
  "You haven't shared this scouting yet, you can invite others user by adding their Email in the box bellow":
    'Vous n’avez pas créé de groupes , vous pouvez le faire sur votre page Profil',
  Share: 'Partager',
  'Do you want to unpublish the scouting?':
    'Voulez-vous annuler la publication du skouting',
  'Do you want to publish the scouting?': 'Voulez-vous publier le skouting ?',
  'You must be logged in to use this feature':
    'Vous devez vous connecter pour utiliser cette fonction',
  'You must have a premium license to use this feature':
    "Vous devez disposer d'une licence premium pour utiliser cette fonction",
  Protagonist: 'Protagoniste',
  Protagonists: 'Protagonistes',
  Color: 'Couleur',
  'No colors available': 'Pas de couleurs disponible',
  Selection: 'Sélection',
  Filters: 'Filtres',
  'Advanced filters': 'Filtres avancés',
  Apply: 'Appliquer',
  'Filter by tag': 'Filtrer par étiquette',
  'No options': 'Pas d’options',
  'Select Tags': 'Sélectionner les étiquettes',
  'Filter by color': 'Filtrer par couleur',
  Title: 'Titre',
  'Write your Description': 'Rédigez votre description',
  'Start Time': 'Début du temps',
  'End time': 'Fin',
  'End time must be after start time': 'La fin doit être postérieure au début',
  'No actions for the selected filters':
    'Pas d’actions pour les filtres sélectionnés',
  'Start creating actions': 'Commencer à créer des actions',
  'No actions in this skouting yet': 'Aucune action dans ce skouting',
  Edit: 'Modifier',
  Remove: 'Supprimer',
  Minutes: 'Minutes',
  'Please process the payment of your invoice':
    'Veuillez traiter le paiement de votre facture',
  Receipt: 'Reçu',
  Algorithm: 'Algorithme',
  'Applied to': 'Appliqué à',
  Clear: 'Clair',
  Checkout: 'Check-out',
  'Add algorithm': 'Ajouter un algorithme',
  'Add all algorithms': 'Ajouter tous les algorithmes',
  selected: 'sélectionné',
  Select: 'Sélectionner',
  Specialty: 'Spécialité',
  'Apply to': 'Appliquer à',
  All: 'Tout',
  Filtered: 'Filtré',
  Time: 'Heure',
  From: 'A partir de',
  To: 'Pour',
  'Search Actions': 'Rechercher des actions',
  Date: 'Date',
  Description: 'Description',
  'Used Tags': 'Tags utilisés',
  Comments: 'Commentaires',
  Statistics: 'Statistiques',
  'Please login to enjoy this feature':
    'connectez-vous pour utiliser cette fonctionnalité',
  'Something went wrong, please try again later.':
    'Une erreur est parvenue, veuillez réessayer ultérieurement',
  'Scouting Statistics': 'Statistiques du Skouting',
  'Global Comments': 'Commentaires généraux',
  'Actions Comments': 'Actions Commentaires',
  'Count By Tags': 'Comptage par étiquettes',
  'No selected actions': 'Aucune action sélectionnée',
  Start: 'Début',
  End: 'Fin',
  Cancel: 'Annuler',
  'No Title': 'No Titre',
  Duration: 'Durée',
  'Quick off': 'Désactivation rapide',
  'Quick On': 'Activation rapide',
  'Skouting link copied to the clipboard':
    'Lien de skouting copié dans le presse-papiers',
  'Make public': 'Publier',
  'Share on Facebook': 'Partagez sur Facebook',
  'Checkout this skouting': 'Consulter ce skouting',
  'Share on Twitter': 'Partagez sur Twitter',
  'Copy link to clipboard': 'Copier le lien vers le presse-papiers',
  Facebook: 'Facebook',
  Twitter: 'Twitter',
  Copy: 'Copie',
  'Share by mail': 'Partager par email',
  'Share on Whatsapp': 'Partagez sur Whatsapp',
  'Share on Telegram': 'Partagez sur Telegram',
  'Make private': 'Rendre privé',
  'Max size of 2MB': 'Taille maximale de 2MB',
  'Update Scouting': 'Actualiser le Skouting',
  'New Scouting': 'Nouveau Vidéo',
  'Please fill the required information':
    'Veuillez renseigner les informations requises',
  Sport: 'Sport',
  'Video Source': 'Source vidéo',
  'Video Time': 'Temps vidéo',
  'You can crop the video specifying a start and end time with the slider':
    'Vous pouvez regarder la vidéo en spécifiant une heure de début et de fin avec le curseur',
  'Write your description': 'Écrivez votre description',
  'Video Referee File': 'Fichier arbitrage vidéo',
  Optional: 'Facultatif',
  'You can upload a compatible video referee file to preload the scouting actions':
    'Vous pouvez télécharger un fichier d’arbitrage vidéo qui soit compatible afin de précharger les actions du skouting',
  'Published at': 'Publié LE',
  'Password updated successfully': 'Mot de passe mis à jour avec succès',
  'Current Password': 'Mot de passe actuel',
  'New Password': 'Nouveau mot de passe',
  'New Password Confirmation': 'Confirmation du nouveau mot de passe',
  'Group name': 'Nom de groupe',
  Members: 'Membres',
  Update: 'Mettre à jour',
  'Nº Of Members': 'Nº De Membres',
  'Nº of Shared Skoutings': 'Nº De skoutings Partagés',
  Modify: 'Modifier',
  'Create New': 'Créer un Nouveau',
  'Profile updated successfully': 'Profil mis à jour avec succès',
  'Allowed JPG, GIF or PNG': 'Extensions autorisées JPG, GIF ou PNG',
  'Profile Image updated successfully':
    'Image du profil mise à jour avec succès',
  Day: 'Jour',
  Month: 'Mois',
  Year: 'Année',
  Female: 'Femme',
  Male: 'Homme',
  Other: 'Autre',
  'Change Avatar': "Changer d'avatar",
  Gender: 'Sexe',
  'Date of Birth': 'Date de naissance',
  'When does the license expire?': 'Quand expire la licence ? ',
  revokeMessage:
    "Vous voulez révoquer votre Clé de l'API, cela implique que tous les ordinateurs qui sont configurés avec cette Clé de l'API doivent indiquer une nouvelle Clé de l'API pour fonctionner correctement. Si vous appuyez sur annuler, la Clé d'API ne changera pas. Si vous appuyez sur ACCEPTER, une nouvelle Clé d'API sera générée et ne pourra pas être révoquée dans les 48 heures.",
  'Api Key Code': 'Code clé Api',
  'Api Key': 'Clé Api',
  Revoke: 'Révoquer',
  Renovate: 'Renouveler',
  'Are you sure you want to remove the selected scoutings? this cannot be undone':
    'Êtes-vous sûr de vouloir supprimer les skoutings sélectionnés ? Cette action ne peut pas être annulée',
  'Are you sure you want to remove this scouting? this cannot be undone':
    'Êtes-vous sûr de vouloir supprimer ce skouting ? Cette action ne peut pas être annulée ',
  Delete: 'Effacer',
  'Bulk Delete': 'Suppression massive',
  'Selected algorithms': 'Algorithmes sélectionnés',
  'Payed ID': 'ID paiement',
  'Payment type': 'Type de paiement',
  Status: 'Statut',
  'Scouting ID': 'ID Skouting',
  'Are you sure you want to remove the selected protagonists? this cannot be undone':
    'Êtes-vous sûr de vouloir supprimer les protagonistes sélectionnés ? Cette action ne peut pas être annulée ',
  'Are you sure you want to remove this protagonist? this cannot be undone':
    'Êtes-vous sûr de vouloir supprimer ce protagoniste ? Cette action ne peut pas être annulée ',
  'Quick action': 'Action rapide',
  'Add tags': 'Ajouter des balises',
  'Are you sure you want to remove the selected tags? this cannot be undone':
    'Êtes-vous sûr de vouloir supprimer les étiquettes sélectionnées ? Cette action ne peut pas être annulée ',
  'Are you sure you want to remove this tag? this cannot be undone':
    'Êtes-vous sûr de vouloir supprimer cette étiquette ? Cette action ne peut pas être annulée ',
  'By Group': 'Par Groupe',
  'Show All': 'Tout Montrer',
  See: 'Voir',
  'My profile': 'Mon Profil',
  Information: 'Information',
  'Quick actions': 'Actions rapides',
  Groups: 'Groupes',
  Licenses: 'Licences',
  Payments: 'Paiements',
  Replay: 'Rejouer',
  'Choose a video to create a personalised analysis and save your best moments':
    'Choisissez une vidéo pour créer une analyse personnalisée et enregistrer vos meilleurs moments',
  'Have better control over your video, choose the moments relevant to you and watch them back at your own speed':
    'Ayez un meilleur contrôle sur votre vidéo, choisissez les moments qui vous intéressent et regardez-les à votre rythme',
  'Share your best moments with friends and family, share your progress privately with your coach or trainer':
    'Partagez vos meilleurs moments avec vos amis et votre famille, partagez vos progrès en privé avec votre coach ou formateur',
  'Log in and pay': 'Connectez-vous et payez',
  Register: "S'inscrire",
  'Register and pay': 'Inscrivez-vous et payez',
  'Follow us': 'Suivez nous',
  'Thank you for buying Skouting Video Replay Software':
    "Merci d'avoir acheté le logiciel Skouting Video Replay",
  'Download your software now': 'Téléchargez votre logiciel maintenant',
  Download: 'Télécharger',
  'License code': 'Code de licence',
  'Upgrade your skouting account for free':
    'Mettez à jour votre compte skouting gratuitement',
  Until: "Jusqu'à",
  '20% discount on RRP': '20% de réduction sur le RRP',
  Renewal: 'Renouvellement',
  'Group invitations': 'invitations de groupe',
  'Host name': "Nom d'hôte",
  Accept: "J'accepte",
  'Are you sure to cancel the invitation':
    "Êtes-vous sûr d'annuler l'invitation?",
  'Are you sure to accept the invitation':
    "Êtes-vous sûr d'accepter l'invitation",
  'Who can tag you in the skoutings': 'Qui peut vous taguer dans les Skoutings',
  'Who can see your statistics for Skouting':
    'Qui peut voir vos statistiques pour Skouting',
  Accepted: 'Accepté',
  Sent: 'Envoyée',
  Denied: 'Refusée',
  Member: 'Membre',
  'Acceptance date': "Date d'acceptation",
  'Invitation status': "État de l'invitation",
  'Group Owner': 'Propriétaire du groupe',
  'All members of the group': 'Tous les membres du groupe',
  'Group details': 'Détails du groupe',
  'Groups to which I belong': "Groupes auxquels j'appartiens",
  'You want to allow the skoutings in which you are tagged to be made public':
    'Vous souhaitez autoriser les skoutings dans lesquels vous êtes tagué à être rendus publics',
  Username: "Nom d'utilisateur",
  'Username already exist': "Nom d'utilisateur existe déjà",
  UsernameRegex:
    'Vous ne pouvez obtenir que les caractères suivants (_-.) Et vous ne pouvez pas avoir de blancs, et au début vous devez avoir @',
  TAX: 'TVA',
  'View Profile': 'Voir Profil',
  'Advanced Filters': 'Filtres avancés',
  'Advanced Actions': 'Actions avancées',
  'Share videos privately': 'Partager des vidéos en privé',
  'Create groups': 'Créer des groupes',
  '20% discount on AI': "20% de réduction sur l'IA",
  Identification: 'Identification',
  'Tag Name': "Nom de l'étiquette",
  'Protagonist Name': "Nom de l'protagoniste",
  'Nº of Occurrences': "Nº d'occurrences",
  Add: 'Ajouter',
  'No Tags': 'Pas d’Étiquettes',
  'Start Action': 'Démarrer Action',
  'Stop Action': 'Arrêter Action',
  'Send Invite': 'Envoyer invitation',
  'Share With Groups': 'Partager avec des Groupes',
  'Shared With': 'Partagé avec',
  'Share with selected groups': 'Partager avec des groupes sélectionnés',
  'Are you sure you want to remove this guest from the group? this cannot be undone':
    'Voulez-vous vraiment supprimer cet invité du groupe? Ça ne peut pas être annulé',
  'Get basic': 'Obtenir le plan de base',
  'Get premium': 'Obtenir le plan premium',
  'Get expert': 'Obtenir le plan expert',
  'Get crédits': 'Ajouter des crédits',
  'days left': 'Jours restants',
  'YOU NEED TO BE LOGGED IN TO SEE THIS SKOUTING':
    'VOUS DEVEZ ÊTRE CONNECTÉ POUR VOIR CE SKOUTING',
  'Subscribe to a Plan': 'Souscrire à un plan',
  "DON'T HAVE AN ACCOUNT YET?": "VOUS N'AVEZ PAS ENCORE DE COMPTE ?",
  'REGISTER NOW': 'INSCRIVEZ-VOUS MAINTENANT!',
  'Activate loop': 'Activer la boucle',
  Volume: 'Volume',
  'your identity inside Skouting': 'votre identité dans Engarde Videos',
  'Trial Account': 'Mise à niveau,',
  'Access fencing video database': 'Accès aux clôtures vidéo DB',
  'With 15 days Skouting trial you can':
    "Avec 15 jours d'essai du Engarde Videos, vous pouvez",
  'The prime platform where users access, analyze and share online videos of fencing tournaments':
    "La première plateforme où les utilisateurs accèdent, analysent et partagent des vidéos en ligne de tournois d'escrime.",
  Fencing: 'Escrime',
  'Video \n technology': 'Vidéo \n technologie',
  'fencing \n results': 'escrime \n résultats',
  'Video \n analysis': 'Vidéo \n analyse',
  'we are developing the new \n technology for fencing':
    "nous développons des nouvelles \n technologies pour l'escrime",
  'are you ready for it?': 'êtes-vous prêts?',
  Categories: 'Catégories',
  Weapon: 'Arme',
  Phase: 'Phase',
  'Type of competition': 'Type de concours',
  Tournament: 'Tournoi',
  Top: 'Haut',
  Discipline: 'Discipline',
  Filter: 'Filtre',
  'Filter applied': 'Filtre appliqué',
  Position: 'Position',
  State: 'Stade',
  Result: 'Résultat',
  'No data': 'Aucune donnée',
  'ARE YOU SURE YOU WANT TO DELETE THIS CATEGORY? THIS CANNOT BE UNDONE':
    'ÊTES-VOUS SÛR DE VOULOIR SUPPRIMER CETTE CATÉGORIE? ÇA NE PEUT PAS ÊTRE ANNULÉ',
  'Category name': 'Nom de la catégorie',
  Successful: 'Réussi',
  FOOTER_SOFTWARE: 'LOGICIEL',
  FOOTER_HARDWARE: 'MATÉRIEL',
  FOOTER_SERVICES: 'SERVICES',
  FOOTER_EVENTS: 'ÉVÉNEMENTS',
  FOOTER_DEVELOPMENT: 'DÉVELOPPEMENT',
  FOOTER_TECHNICAL_SUPPORT_TRAINING: 'SUPPORT TECHNIQUE & FORMATION',
  FOOTER_SOLUTIONS: 'SOLUTIONS',
  FOOTER_COMPETITION: 'COMPÉTITION',
  FOOTER_PERFORMANCE: 'PERFORMANCE',
  FOOTER_INDUSTRY: 'INDUSTRIE',
  FOOTER_FOOTBALL: 'FOOTBALL',
  FOOTER_SURVEILLANCE: 'SURVEILLANCE',
  FOOTER_ABOUT_US: 'NOUS',
  FOOTER_WHO_WE_ARE: 'QUI NOUS SOMMES',
  FOOTER_CONTACT: 'CONTACT',
  FOOTER_TERMS_AND_CONDITIONS: 'TERMES ET CONDITIONS',
  FOOTER_PRIVACY_POLICY: 'POLITIQUE DE CONFIDENTIALITÉ',
  'Buy SK': 'Ajouter des crédits',
  'one-time payment': 'paiement de 5000 crédits',
  'Your SK Tokens': 'Vos tokens SK',
  Algorithms: 'Algorithmes',
  subscribers: 'abonnés',
  'Mi Canal': 'Ma Chaîne',
  Subscribe: 'Souscrire',
  Suscrito: 'Souscrit',
  Publish: 'Publier',
  'Channel description': 'Description du canal',
  Personalizar: 'Personnaliser',
  'Click to upload': 'Cliquez pour télécharger',
  'or drag and drop': 'ou par glisser-déposer',
  'SVG, PNG or JPG': 'SVG, PNG ou JPG',
  View: 'Voir',
  Generate: 'Générer',
  basic_monthly: '2,99 € / mois',
  basic_annual: '19,99 € / an',
  premium_monthly: '6,99 € / mois',
  premium_annual: '59,88 € / an',
  expert_monthly: '34,99 € / mois',
  expert_annual: '144,99 € / an',
  license_annual: '137,99 € / an',
  credits_fixed: '5,25 € / paiement pour 5000 crédits',
  fixed_storage_1GB: '2,00 € / paiement mensuel pour 1 Go',
  fixed_storage_10GB: '5,00 € / paiement mensuel pour 10 Go',
  fixed_storage_100GB: '20,00 € / paiement mensuel pour 100 Go',
  fixed_storage_1TB: '50,00 € / paiement mensuel pour 1 To',
  STORAGE: 'Stockage',
  'add-credits': "ajouter de l'espace de stockage",
  credits_fixed_hidden: '5,25 €',
  subscription_modal_title: 'Abonnement expiré',
  subscription_modal_text:
    "Votre abonnement a expiré et il n'a pas été possible de procéder au paiement avec votre mode de paiement actuel. Veuillez effectuer le paiement avec un nouveau mode de paiement ou commencer le processus de résiliation. Notre équipe de support vous contactera pour vous aider à télécharger tous vos fichiers multimédias.",
  download_process_button_text: 'Commencer le processus de téléchargement',
  go_to_payment: 'Aller au paiement',
  after_message:
    "Dans les prochaines heures, notre équipe d'assistance vous contactera",
  credits_fixed_10k: '10,00 € / paiement pour 10000 crédits',
  credits_fixed_hidden_10k: '10,00 €',
  credits_fixed_50k: '50,00 € / paiement pour 50000 crédits',
  credits_fixed_hidden_50k: '50,00 €',
  credits_fixed_100k: '100,00 € / paiement pour 100000 crédits',
  credits_fixed_hidden_100k: '100,00 €',
  credits_fixed_500k: '500,00 € / paiement pour 500000 crédits',
  credits_fixed_hidden_500k: '500,00 €',
  credits_fixed_1M: '1000,00 € / paiement pour 1000000 crédits',
  credits_fixed_hidden_1M: '1000,00 €',
  default_value_category_element: 'Sélectionnez une plage de dates',
  UID: 'UID',
  title: 'Se désabonner',
  warning:
    'Attention : Cette action est irréversible. Une fois désabonné, vous ne recevrez plus aucune communication de notre part.',
  emailLabel: 'Confirmez votre email',
  emailRequired: "L'email est requis",
  emailInvalid: 'Adresse email invalide',
  confirmLabel: 'Je comprends que cette action ne peut pas être annulée',
  confirmRequired: 'Vous devez confirmer pour vous désabonner',
  submitButton: 'Se désabonner',
  comments_title: 'Commentaires',
  comments_noMore: 'Plus de commentaires',
  comments_add: 'Ajouter un commentaire...',
  comments_cancel: 'Annuler',
  comments_submit: 'Commenter',
  comments_sending: 'Envoi...',
  comments_edit: 'Modifier le commentaire',
  comments_delete: 'Supprimer le commentaire',
  comments_save: 'Enregistrer',
  comments_edited: 'modifié',
  time_just_now: "à l'instant",
  time_minutes_one: 'il y a {{minutes}} minute',
  time_minutes_other: 'il y a {{minutes}} minutes',
  time_hours_one: 'il y a {{hours}} heure',
  time_hours_other: 'il y a {{hours}} heures',
  time_days_one: 'il y a {{days}} jour',
  time_days_other: 'il y a {{days}} jours',
  make_payment: 'Effectuer le paiement',
  unauthenticated_make_payment: 'Se connecter pour effectuer le paiement',
};

export default lang;
