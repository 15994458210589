import React, { useMemo } from 'react';
import MuiButton from '@mui/material/Button';
import { ButtonProps as MuiButtonProps } from '@mui/material/Button/Button';

import theme from 'themes/scouting-light';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

const blackTheme = createTheme({
  ...theme,
  palette: {
    primary: {
      main: theme.palette.common.black,
      dark: theme.palette.primary.main,
    },
  },
});

const whiteTheme = createTheme({
  ...theme,
  palette: {
    primary: {
      main: theme.palette.common.white,
      dark: theme.palette.primary.main,
    },
  },
});

interface ButtonProps
  extends Omit<MuiButtonProps, 'disableElevation' | 'color'> {
  rounded?: boolean;
  color?: MuiButtonProps['color'] | 'light' | 'dark';
  variant?: MuiButtonProps['variant'];
  noPadding?: boolean;
}

// @ts-ignore
const BaseButton = styled(MuiButton)(() => ({
  letterSpacing: 2.5,
  '&:hover': {
    boxShadow: 'none',
  },
}));

const RoundedButton = styled(BaseButton)(() => ({
  borderRadius: 20,
}));

const Button: React.FC<ButtonProps> = ({
  rounded,
  noPadding,
  color,
  ...props
}) => {
  const style = useMemo(
    () => (noPadding ? {} : { paddingLeft: '28px', paddingRight: '28px' }),
    [noPadding],
  );

  let ButtonToRender = BaseButton;

  if (rounded) {
    ButtonToRender = RoundedButton;
  }

  if (color === 'dark' || color === 'light') {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={color === 'dark' ? blackTheme : whiteTheme}>
          <ButtonToRender
            sx={{
              ...style,
              '&.Mui-disabled': {
                backgroundColor: 'rgba(255, 255, 255, 0.12)',
              },
            }}
            {...props}
            color='primary'
            disableElevation
          />
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }

  return (
    <ButtonToRender
      sx={{
        ...style,
        '&.Mui-disabled': {
          backgroundColor: 'rgba(255, 255, 255, 0.12)',
        },
      }}
      {...props}
      className={
        !color || color === 'primary'
          ? `${props.className} bg-blue-500`
          : props.className
      }
      color={color}
      disableElevation
    />
  );
};

export default Button;
