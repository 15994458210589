const LITERALS = {
  MENU: {
    MANAGER_COOKIES: 'Manager Cookies',
    PRIVACY_POLICY: 'Privacy Policy',
    FAQ: 'FAQ',
  },
  BUTTON: {
    LEARN_MORE: 'Learn More',
    SAVE: 'Save',
    ACCEPT: 'Accept',
    DECLINE: 'Decline',
    ACCEPT_ALL: 'Accept all',
    DECLINE_ALL: 'Decline all',
    HERE: 'here',
    PRIVACY_POLICY: 'Privacy Policy',
  },
  CONSENTS: {
    BANNER: {
      TITLE: 'Privacy Policy',
      DESCRIPTION:
        'We collect and process your personal information for the following purposes. To learn more, please read our',
    },
    MODAL: {
      TITLE: 'Cookie Settings',
      DESCRIPTION:
        "Some essential features just won't work without cookies and having other cookies switched off can seriously affect the way you'll be able to enjoy our services. Please check your cookie settings below and turn on any cookies you're happy with.  To learn more, please read our",
      COOKIES: {
        OPT_OUT: 'Opt-out',
        NECESSARY: {
          TITLE: 'Strictly necessary cookies',
          DESCRIPTION:
            '“Strictly necessary” cookies are on by default but can be turned off in your browser settings.',
          APPS: {
            OWN: {
              TITLE: 'Privacy Manager',
              REQUIRED: 'always required',
              DESCRIPTION: 'Store this configuration for recurrent visits.',
            },
          },
        },
        PERFORMANCE: {
          TITLE: 'Performance Cookies',
          DESCRIPTION:
            'These help us make sure that the website is working properly and fix any errors. And they let us try out different ideas.',
          APPS: {
            GOOGLE_TAG_MANAGER: {
              TITLE: 'Google Tag Manager',
              DESCRIPTION:
                'Help us understand how people are using our services, so we can make them better.',
            },
            YOUTUBE: {
              TITLE: 'Youtube',
              DESCRIPTION:
                'Cookie configurada por YouTube para rastrear las vistas de videos incrustados en los sitios de Skouting.',
            },
          },
        },
      },
    },
  },
  SKTOKES: {
    TABLE: {
      TITLE: 'Transactions of the month',
      HEAD: {
        DATE: 'DATE',
        MINUTES: 'MINUTES',
        SERVICES: 'SERVICES',
      },
    },
  },
  LICENSE: {
    BUY: 'Buy',
    FILTER: 'Filter',
    STATUS: {
      ALL: 'All',
      ACTIVE: 'Active',
      INACTIVE: 'Inactive',
      EXPIRED: 'Expired',
      REVOKED: 'Revoked',
      ACTIVE_REVOKED: 'Active revoked',
      INACTIVE_REVOKED: 'Inactive revoked',
      EXPIRED_REVOKED: 'Expired revoked',
    },
    TABLE: {
      HEAD: {
        LICENSE_AVAILABLE: 'License Available',
        LICENSE_TYPE: 'Type',
        CREATE_DATE: 'Creation',
        ACTIVATION_DATE: 'Activation',
        VALID_UNTIL: 'Validity',
        STATUS: 'Status',
        ACTIONS: 'Actions',
      },
    },
    ACTION: {
      COPIED_CLIPBOARD: 'License copied to clipboard',
    },
    MESSAGE: {
      NOT_ACTIVATED: 'Not activated',
    },
  },
  ENGARDE_SERVICES: {
    ORGANISM: 'Organism',
    EVENT: 'Event',
    COMPETITION: 'Competition',
    PHASE: 'Phase',
    PHASE_OPTIONS: {
      NOT_SPECIFIED: 'Not specified',
      POULES: 'Poules',
      TABLEAU: 'Tableau',
    },
  },
  SUBSCRIPTION: {
    PAYMENT_MODE: {
      MONTHLY: 'Per month / Monthly payment',
      ANNUAL: 'Per year',
    },
    BUTTON: {
      COUPON_CODE: 'Coupon Code',
      COMING_SOON: 'Coming Soon',
      ALREADY_PURCHASED: 'Already Purchased',
      INCLUDED: 'Included',
      BUY_NOW: 'Buy Now',
    },
    PLANS: {
      BASIC: {
        TITLE: 'Basic',
        BENEFIT_1: 'Watch, comment and share',
        BENEFIT_2: 'Access video database',
        BENEFIT_3: 'Create Video Analysis',
        BENEFIT_4: 'Access video control bar',
        BENEFIT_5: 'Create groups',
        BENEFIT_6: 'Advanced statistics',
        BENEFIT_7: '3.000 SK Tokens Monthly / 20.000 Tokens Annual',
        BENEFIT_8: '2 % of discount in Tokens',
      },
      EXPERT: {
        TITLE: 'Expert',
        BENEFIT_1: 'All benefits from Premium +',
        BENEFIT_2: 'Skouting Creator License',
        BENEFIT_3: '100 GB Store',
        BENEFIT_4: '35.000 SK Tokens Monthly / 150.000 SK Tokens Annual',
        BENEFIT_5: '5% of discount in Tokens',
      },
      PREMIUM: {
        TITLE: 'Premium',
        BENEFIT_1: 'All benefits from Basic+',
        BENEFIT_2: '10GB storage (coming soon)',
        BENEFIT_3: 'In-depth reports and statistics',
        BENEFIT_4: 'Access to AI analysis',
        BENEFIT_5: '2 analysis at the same time',
        BENEFIT_6: 'Skouting Creator License',
        BENEFIT_7: '7.000 SK Tokens Monthly / 60.000 SK Tokens Annual',
        BENEFIT_8: '3 % of discount in Tokens',
        BENEFIT_9: 'All benefits from basic +',
      },
    },
  },
  GROUPS: {
    ERROR: {
      DUPLICATE_EMAIL: 'There are members with the same email',
    },
    ACTION: {
      DELETE_GROUP:
        'Are you sure you want to remove this group? This cannot be undone',
      DELETE_MEMBER:
        'Are you sure you want to remove this guest from the group? This cannot be undone',
    },
  },
  USER: {
    PROFILE: {
      TAB: {
        MEMBERSHIPS: 'Memberships',
      },
      MEMBERSHIP: {
        TABLE: {
          FIRST_NAME: 'First Name',
          LAST_NAME: 'Last Name',
          GENDER: 'Gender',
          WEAPONS: 'Weapons',
          LATERALITY: 'Laterality',
          COUNTRY: 'Country',
          BIRTHDAY: 'Birthday',
          ORGANISM: 'Organism',
          ORGANISM_TYPE: 'Type',
        },
        DETAILS: {
          LICENSE: 'License',
          DUE_DATE: 'Due Date',
          STATUS: 'Status',
          ACTIVE: 'Active',
          INACTIVE: 'Inactive',
        },
      },
    },
  },
};

const lang = {
  ...LITERALS,
  'Contact us': 'Contact us',
  'Web develop by': 'Web develop by',
  Yes: 'Yes',
  No: 'No',
  'Without results': 'Without results',
  'Order by': 'Order by',
  Newest: 'Newest',
  Oldest: 'Oldest',
  'Please input your email!': 'Please enter your email',
  'You must enter a valid Email address!':
    'You must enter a valid Email address!',
  Email: 'Email',
  'Reset Password': 'Reset Password',
  Search: 'Search',
  Ok: 'Ok',
  'Video Replay FILE': 'Video Replay FILE',
  'Please input your Password!': 'Please enter your password',
  Password: 'Password',
  'Forgot Password?': 'Forgot Password?',
  'Pre-Register': 'Pre-Register',
  'Log In': 'Connect',
  Create: 'Create',
  'Premium Account': 'Premium Account',
  'Trial Version': 'Trial Version',
  'TO SHARE OR WATCH SHARED VIDEOS YOU NEED TO BE PREMIUM':
    'TO SHARE OR WATCH SHARED VIDEOS YOU NEED TO BE PREMIUM',
  'WHAT IS PREMIUM?': 'WHAT IS PREMIUM?',
  Notifications: 'Notifications',
  ErrorSuccessPaypal:
    'An error occurred while processing the payment with PayPal',
  SuccessPaypal: 'The payment was processed correctly',
  ErrorPayPaypal: 'Payment was not made, please try again',
  CancelPayPaypal: 'Payment not made, the user canceled the process',
  Premium: 'Premium',
  'Watch, comment and share': 'Watch, comment and share',
  'Access video database': 'Access video database',
  'Create Skoutings': 'Create Video Analisys',
  'Access video control bar': 'Access video control bar',
  'Advanced statistics': 'Advanced statistics',
  '10GB storage (coming soon)': '10GB storage (coming soon)',
  'In-depth reports and statistics': 'In-depth reports and statistics',
  'Access to AI analysis': 'Access to AI analysis',
  '2 Analysis at the same time': '2 Analysis at the same time',
  'Special Video Analysis Software': 'Special Video Analysis Software',
  'All benefits fromPremium +': 'All benefits from Premium +',
  '1 GB storage': '1 GB storage',
  '10 Analysis at the same time': '10 Analysis at the same time',
  'Special Algorithms': 'Special Algorithms',
  'Faster analysis': 'Faster analysis',
  'Free Trial': 'Free Trial',
  'Buy Now': 'Buy Now',
  'My Profile': 'My Profile',
  'Change Password': 'Change Password',
  'Legal warning': 'Legal Notice',
  'Log Out': 'Log Out',
  'Are you sure? all the unsaved changes will be lost':
    'Are you sure? all the unsaved changes will be lost',
  Exit: 'Exit',
  Save: 'Save',
  Public: 'Public',
  Private: 'Private',
  'This field is required!': 'This field is required!',
  Name: 'Name',
  Group: 'Group',
  'Time Controls': 'Time Controls',
  'Playback Rate': 'Playback Rate',
  Simple: 'Simple',
  Off: 'Off',
  On: 'On',
  Cover: 'Cover',
  Category: 'Category',
  Tags: 'Tags',
  'Created At': 'Created At',
  Visibility: 'Visibility',
  "You can't add a duplicated tag unless you add a new protagonist first":
    "You can't add a duplicated tag unless you add a new protagonist first",
  'only two tags/protagonist can be concatenated':
    'only two tags/protagonist can be concatenated',
  'You have to create an action before concatenating tags':
    'You have to create an action before concatenating tags',
  'You have to add a tag or protagonist first':
    'You have to add a tag or protagonist first',
  Country: 'Country',
  'First Name': 'First Name',
  'Last Name': 'Last Name',
  'Password must have at least 8 character and contain at least one number':
    'Password must have at least 8 character and contain at least one number',
  'Password Confirmation': 'Password Confirmation',
  'Password must match!': 'Password must match!',
  'Please enter a invitation code':
    'Please enter an Invitation code, or leave it blank',
  'Invitation Code': 'Invitation Code (NOT MANDATORY)',
  'Invitation code is not valid': 'Invitation code is not valid',
  'Already have an account?': 'Already have an account?',
  'Create a skouting account': 'Create a skouting account',
  'Login skouting': 'Login skouting',
  'Go Back': 'Go Back',
  Sports: 'Sports',
  Others: 'Others',
  Asc: 'Asc',
  Desc: 'Desc',
  'All Sports': 'All Sports',
  Videos: 'Videos',
  'My Scoutings': 'My Skoutings',
  Scoutings: 'Skoutings',
  'Scoutings Shared with me': 'Skoutings Shared with me',
  Hi: 'Hi',
  Total: 'Total',
  'Your payment was not approved': 'Your payment was not approved',
  'Please try again with another card or a different payment method':
    'Please try again with another card or a different payment method',
  'Your account has been activated': 'Your account has been activated',
  'You can now access all the features of a Premium user':
    'You can now access all the features of a Premium user',
  'Visit our FAQs for more information': 'Visit our FAQs for more information',
  'Video Replay BASIC': 'Video Replay BASIC',
  Monthly: 'Monthly',
  Annual: 'Annual',
  Permanent: 'Permanent',
  'Software license': 'Software license',
  'Already had a free license': 'Already had a free license',
  Continue: 'Continue',
  '13.99 € / month * 1 month': '13.99 € / month * 1 month',
  '4.99 € / month * 12 months (You save 108 €)':
    '4.99 € / month * 12 months (You save 108 €)',
  'Redeem a code': 'Redeem a coupon',
  'Enter your coupon code below': 'Enter your coupon code below',
  Redeem: 'Redeem',
  'Free trial (15 Days)': 'Free trial (15 Days)',
  'Free trial': 'Free trial',
  Home: 'Home',
  'Do you want to remove this action?': 'Do you want to remove this action?',
  'No comments yet!': 'No comments yet!',
  'Write a comment': 'Write a comment',
  'Internal Share': 'Internal Share',
  Actions: 'Actions',
  "You haven't shared this scouting yet, you can invite others user by adding their Email in the box bellow":
    "You haven't shared this skouting yet, you can invite other users by adding their Email in the box bellow",
  Share: 'Share',
  'Do you want to unpublish the scouting?':
    'Do you want to unpublish the Skouting?',
  'Do you want to publish the scouting?':
    'Do you want to publish the Skouting?',
  'You must be logged in to use this feature':
    'You must be logged in to use this feature',
  'You must have a premium license to use this feature':
    'You must have a premium license to use this feature',
  Protagonist: 'Protagonist',
  Protagonists: 'Protagonists',
  Color: 'Colour',
  'No colors available': 'No colors available',
  Selection: 'Selection',
  Filters: 'Filters',
  'Advanced filters': 'Advanced filters',
  Apply: 'Apply',
  'Filter by tag': 'Filter by tag',
  'No options': 'No options',
  'Select Tags': 'Select Tags',
  'Filter by color': 'Filter by Colour',
  Title: 'Title',
  'Write your Description': 'Write your Description',
  'Start Time': 'Start Time',
  'End time': 'End time',
  'End time must be after start time': 'End time must be after start time',
  'No actions for the selected filters': 'No actions for the selected filters',
  'Start creating actions': 'Start creating actions',
  'No actions in this skouting yet': 'No actions in this skouting yet',
  Edit: 'Edit',
  Remove: 'Remove',
  Minutes: 'Minutes',
  'Please process the payment of your invoice':
    'Please process the payment of your invoice',
  Receipt: 'Receipt',
  Algorithm: 'Algorithm',
  'Applied to': 'Applied to',
  Clear: 'Clear',
  Checkout: 'Checkout',
  'Add algorithm': 'Add algorithm',
  'Add all algorithms': 'Add all algorithms',
  selected: 'selected',
  Select: 'Select',
  Specialty: 'Specialty',
  'Apply to': 'Apply to',
  All: 'All',
  Filtered: 'Filtered',
  Time: 'Time',
  From: 'From',
  To: 'To',
  'Search Actions': 'Search Actions',
  Date: 'Date',
  Description: 'Description',
  'Used Tags': 'Used Tags',
  Comments: 'Comments',
  Statistics: 'Statistics',
  'Please login to enjoy this feature': 'Please log in to enjoy this feature',
  'Something went wrong, please try again later.':
    'Something went wrong, please try again later.',
  'Scouting Statistics': 'Skouting Statistics',
  'Global Comments': 'Global Comments',
  'Actions Comments': 'Actions Comments',
  'Count By Tags': 'Count By Tags',
  'No selected actions': 'No selected actions',
  Start: 'Start',
  End: 'End',
  Cancel: 'Cancel',
  'No Title': 'No Title',
  Duration: 'Duration',
  'Quick off': 'Quick off',
  'Quick On': 'Quick On',
  'Skouting link copied to the clipboard':
    'Skouting link copied to the clipboard',
  'Make public': 'Make public',
  'Share on Facebook': 'Share on Facebook',
  'Checkout this skouting': 'Checkout this skouting',
  'Share on Twitter': 'Share on Twitter',
  'Copy link to clipboard': 'Copy link to clipboard',
  Facebook: 'Facebook',
  Twitter: 'Twitter',
  Copy: 'Copy',
  'Share by mail': 'Share by mail',
  'Share on Whatsapp': 'Share on Whatsapp',
  'Share on Telegram': 'Share on Telegram',
  'Make private': 'Make private',
  'Max size of 2MB': 'Max size of 2MB',
  'Update Scouting': 'Update Skouting',
  'New Scouting': 'New Video',
  'Please fill the required information':
    'Please fill the required information',
  Sport: 'Sport',
  'Video Source': 'Video Source',
  'Video Time': 'Video Time',
  'You can crop the video specifying a start and end time with the slider':
    'You can crop the video by specifying a start and end time with the slider',
  'Write your description': 'Write your description',
  'Video Referee File': 'Video Referee File',
  Optional: 'Optional',
  'You can upload a compatible video referee file to preload the scouting actions':
    'You can upload a compatible video referee file to preload the Skouting actions',
  'Published at': 'Published at',
  'Password updated successfully': 'Password updated successfully',
  'Current Password': 'Current Password',
  'New Password': 'New Password',
  'New Password Confirmation': 'New Password Confirmation',
  'Group name': 'Group name',
  Members: 'Members',
  Update: 'Update',
  'Nº Of Members': 'Nº Of Members',
  'Nº of Shared Skoutings': 'Nº of Shared Skoutings',
  Modify: 'Modify',
  'Create New': 'Create New',
  'Profile updated successfully': 'Profile updated successfully',
  'Allowed JPG, GIF or PNG': 'Allowed JPG, GIF or PNG',
  'Profile Image updated successfully': 'Profile Image updated successfully',
  Day: 'Day',
  Month: 'Month',
  Year: 'Year',
  Female: 'Female',
  Male: 'Male',
  Other: 'Other',
  'Change Avatar': 'Change Avatar',
  Gender: 'Gender',
  'Date of Birth': 'Date of Birth',
  'When does the license expire?': 'When does the license expire?',
  revokeMessage:
    'You want to revoke your API KEY, this implies that all the computers that are configured with this API KEY require to indicate a new API KEY to function correctly. If you press cancel the API KEY will not change. If you press ACCEPT a new API KEY will be generated and cannot be revoked again within 48 hours',
  'Api Key Code': 'Api Key Code',
  'Api Key': 'Api Key',
  Revoke: 'Revoke',
  Renew: 'Renew',
  'Are you sure you want to remove the selected scoutings? this cannot be undone':
    'Are you sure you want to remove the selected scoutings? this cannot be undone',
  'Are you sure you want to remove this scouting? this cannot be undone':
    'Are you sure you want to remove this scouting? this cannot be undone',
  Delete: 'Delete',
  'Bulk Delete': 'Bulk Delete',
  'Selected algorithms': 'Selected algorithms',
  'Payed ID': 'Payed ID',
  'Payment type': 'Payment type',
  Status: 'Status',
  'Scouting ID': 'Scouting ID',
  'Are you sure you want to remove the selected protagonists? this cannot be undone':
    'Are you sure you want to remove the selected protagonists? this cannot be undone',
  'Are you sure you want to remove this protagonist? this cannot be undone':
    'Are you sure you want to remove this protagonist? this cannot be undone',
  'Quick action': 'Quick Action',
  'Add tags': 'Add tags',
  'Are you sure you want to remove the selected tags? this cannot be undone':
    'Are you sure you want to remove the selected tags? this cannot be undone',
  'Are you sure you want to remove this tag? this cannot be undone':
    'Are you sure you want to remove this tag? this cannot be undone',
  'By Group': 'By Group',
  'Show All': 'Show All',
  See: 'See',
  'My profile': 'My profile',
  Information: 'Information',
  'Quick actions': 'Quick actions',
  Groups: 'Groups',
  Licenses: 'Licenses',
  Payments: 'Payments',
  Replay: 'Replay',
  'Choose a video to create a personalised analysis and save your best moments':
    'Choose a video to create a personalised analysis and save your best moments',
  'Have better control over your video, choose the moments relevant to you and watch them back at your own speed':
    'Have better control over your video, choose the moments relevant to you and watch them back at your own speed',
  'Share your best moments with friends and family, share your progress privately with your coach or trainer':
    'Share your best moments with friends and family, share your progress privately with your coach or trainer',
  'Log in and pay': 'Log in and pay',
  Register: 'Register',
  'Register and pay': 'Register and pay',
  'Follow us': 'Follow us',
  'Thank you for buying Skouting Video Replay Software':
    'Thank you for buying Skouting Video Replay Software',
  'Download your software now': 'Download your software now',
  Download: 'Download',
  'License code': 'License code',
  'Upgrade your skouting account for free':
    'Upgrade your skouting account for free',
  Until: 'Until',
  '20% discount on RRP': '20% discount on RRP',
  Renewal: 'Renewal',
  'Group invitations': 'Group invitations',
  'Host name': 'Host name',
  Accept: 'Accept',
  'Are you sure to cancel the invitation':
    'Are you sure to cancel the invitation',
  'Are you sure to accept the invitation':
    'Are you sure to accept the invitation',
  'Who can tag you in the skoutings': 'Who can tag you in the Skoutings',
  'Who can see your statistics for Skouting':
    'Who can see your statistics for Skouting',
  Accepted: 'Accepted',
  Sent: 'Sent',
  Denied: 'Denied',
  Decline: 'Decline',
  Member: 'Member',
  'Acceptance date': 'Acceptance date',
  'Invitation status': 'Invitation status',
  'Group Owner': 'Group Owner',
  'All members of the group': 'All members of the group',
  'Group details': 'Group details',
  'Groups to which I belong': 'Groups to which I belong',
  'You want to allow the skoutings in which you are tagged to be made public':
    'You want to allow the skoutings in which you are tagged to be made public',
  Username: 'Username',
  'Username already exist': 'Username already exist',
  UsernameRegex:
    'You can only get the following characters (_-.) And you cannot have blanks, and at the beginning you must have @',
  TAX: 'TAX',
  'View Profile': 'View Profile',
  'Advanced Filters': 'Advanced Filters',
  'Advanced Actions': 'Advanced Actions',
  'Share videos privately': 'Share videos privately',
  'Create groups': 'Create groups',
  '20% discount on AI': '20% discount on AI',
  Identification: 'Identification',
  'Tag Name': 'Tag Name',
  'Protagonist Name': 'Protagonist Name',
  'Nº of Occurrences': 'Nº of Occurrences',
  Add: 'Add',
  'No Tags': 'No Tags',
  'Start Action': 'Start Action',
  'Stop Action': 'Stop Action',
  'Send Invite': 'Send Invite',
  'Share With Groups': 'Share With Groups',
  'Shared With': 'Shared With',
  'Share with selected groups': 'Share with selected groups',
  'Are you sure you want to remove this guest from the group? this cannot be undone':
    'Are you sure you want to remove this guest from the group? this cannot be undone',
  'Get basic': 'Get basic plan',
  'Get premium': 'Get premium plan',
  'Get expert': 'Get expert plan',
  'Get credits': 'Add more credits',
  'days left': 'Days left',
  'YOU NEED TO BE LOGGED IN TO SEE THIS SKOUTING':
    'YOU NEED TO BE LOGGED IN TO SEE THIS SKOUTING',
  'Subscribe to a Plan': 'Subscribe to a Plan',
  "DON'T HAVE AN ACCOUNT YET?": "DON'T HAVE AN ACCOUNT YET?",
  'REGISTER NOW': 'REGISTER NOW',
  'Activate loop': 'Activate loop',
  Volume: 'Volume',
  'your identity inside Skouting': 'your identity inside Engarde Videos',
  'Trial Account': 'Upgrade your trial,',
  'Access fencing video database': 'Access fencing video database',
  'With 15 days Skouting trial you can':
    'With 15 days Engarde Videos trial you can',
  'The prime platform where users access, analyze and share online videos of fencing tournaments':
    'The prime platform where users access, analyze and share online videos of fencing tournaments',
  Fencing: 'Fencing',
  'Video \n technology': 'Video \n technology',
  'fencing \n results': 'fencing \n results',
  'Video \n analysis': 'Video \n analysis',
  'we are developing the new \n technology for fencing':
    'we are developing the new \n technology for fencing',
  'are you ready for it?': 'are you ready for it?',
  Categories: 'Categories',
  Weapon: 'Weapon',
  Phase: 'Phase',
  'Type of competition': 'Type of competition',
  Tournament: 'Tournament',
  Top: 'Top',
  Discipline: 'Discipline',
  Filter: 'Filter',
  'Filter applied': 'Filter applied',
  Position: 'Position',
  State: 'State',
  Result: 'Result',
  'No data': 'No data',
  'ARE YOU SURE YOU WANT TO DELETE THIS CATEGORY? THIS CANNOT BE UNDONE':
    'ARE YOU SURE YOU WANT TO DELETE THIS CATEGORY? THIS CANNOT BE UNDONE',
  'Category name': 'Category name',
  Order: 'Order',
  Successful: 'Successful',
  FOOTER_SOFTWARE: 'SOFTWARE',
  FOOTER_HARDWARE: 'HARDWARE',
  FOOTER_SERVICES: 'SERVICES',
  FOOTER_EVENTS: 'EVENTS',
  FOOTER_DEVELOPMENT: 'DEVELOPMENT',
  FOOTER_TECHNICAL_SUPPORT_TRAINING: 'TECHNICAL SUPPORT & TRAINING',
  FOOTER_SOLUTIONS: 'SOLUTIONS',
  FOOTER_COMPETITION: 'COMPETITION',
  FOOTER_PERFORMANCE: 'PERFORMANCE',
  FOOTER_INDUSTRY: 'INDUSTRY',
  FOOTER_FOOTBALL: 'SOCCER',
  FOOTER_SURVEILLANCE: 'SURVEILLANCE',
  FOOTER_ABOUT_US: 'ABOUT US',
  FOOTER_WHO_WE_ARE: 'WHO WE ARE',
  FOOTER_CONTACT: 'CONTACT',
  FOOTER_TERMS_AND_CONDITIONS: 'TERMS AND CONDITIONS',
  FOOTER_PRIVACY_POLICY: 'PRIVACY POLICY',
  'Buy SK': 'Add more credits',
  'one-time payment': 'payment for 5000 credits',
  'Your SK Tokens': 'Your SK Tokens',
  Algorithms: 'Algorithms',
  subscribers: 'subscribers',
  'Mi Canal': 'My Channel',
  Subscribe: 'Subscribe',
  Suscrito: 'Subscribed',
  Publish: 'Publish',
  'Channel description': 'Channel description',
  Personalizar: 'Customize',
  'Click to upload': 'Click to upload',
  'or drag and drop': 'or drag and drop',
  'SVG, PNG or JPG': 'SVG, PNG or JPG',
  View: 'View',
  Generate: 'Generate',
  basic_monthly: '2.99 € / month',
  basic_annual: '19.99 € / year',
  premium_monthly: '6.99 € / month',
  premium_annual: '59.88 € / year',
  expert_monthly: '34.99 € / month',
  expert_annual: '144.99 € / year',
  license_annual: '137.99 € / year',
  credits_fixed: '5.25 € / payment per 5000 credits',
  fixed_storage_1GB: '2.00 € / monthly payment for 1GB',
  fixed_storage_10GB: '5.00 € / monthly payment for 10GB',
  fixed_storage_100GB: '20.00 € / monthly payment for 100GB',
  fixed_storage_1TB: '50.00 € / monthly payment for 1TB',
  STORAGE: 'Storage',
  'add-credits': 'add more storage',
  credits_fixed_hidden: '5,25 €',
  subscription_modal_title: 'Expired Subscription',
  subscription_modal_text:
    'Your subscription has expired and it has not been possible to process the payment with your current payment method. Please make the payment with a new payment method or start the cancellation process. Our support team will contact you to help you download all your media files.',
  download_process_button_text: 'Start download process',
  go_to_payment: 'Go to payment',
  after_message: 'In the next few hours, our support team will contact you',
  credits_fixed_10k: '10.00 € / payment for 10000 credits',
  credits_fixed_hidden_10k: '10.00 €',
  credits_fixed_50k: '50.00 € / payment for 50000 credits',
  credits_fixed_hidden_50k: '50.00 €',
  credits_fixed_100k: '100.00 € / payment for 100000 credits',
  credits_fixed_hidden_100k: '100.00 €',
  credits_fixed_500k: '500.00 € / payment for 500000 credits',
  credits_fixed_hidden_500k: '500.00 €',
  credits_fixed_1M: '1000.00 € / payment for 1000000 credits',
  credits_fixed_hidden_1M: '1000.00 €',
  default_value_category_element: 'Select a date range',
  UID: 'UID',
  title: 'Unsubscribe',
  warning:
    'Warning: This action is irreversible. Once you unsubscribe, you will no longer receive any communications from us.',
  emailLabel: 'Confirm your email',
  emailRequired: 'Email is required',
  emailInvalid: 'Invalid email address',
  confirmLabel: 'I understand that this action cannot be undone',
  confirmRequired: 'You must confirm to unsubscribe',
  submitButton: 'Unsubscribe',
  comments_title: 'Comments',
  comments_noMore: 'No more comments',
  comments_add: 'Add a comment...',
  comments_cancel: 'Cancel',
  comments_submit: 'Comment',
  comments_sending: 'Sending...',
  comments_edit: 'Edit comment',
  comments_delete: 'Delete comment',
  comments_save: 'Save',
  comments_edited: 'edited',
  time_just_now: 'just now',
  time_minutes_one: '{{minutes}} minute ago',
  time_minutes_other: '{{minutes}} minutes ago',
  time_hours_one: '{{hours}} hour ago',
  time_hours_other: '{{hours}} hours ago',
  time_days_one: '{{days}} day ago',
  time_days_other: '{{days}} days ago',
  make_payment: 'Make Payment',
  unauthenticated_make_payment: 'Log in to make payment',
};

export default lang;
