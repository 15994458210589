import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import CookieConsent, { OPTIONS, VISIBLE_OPTIONS } from 'react-cookie-consent';

import Button from '../Buttons/Button';

import CookiesSettings from './CookieSettings';
import { initGTM } from 'helpers/gtm';
import { COOKIE_NAME, hasCookieConsents } from 'helpers/consents';
import { Link } from 'react-router-dom';
import { ROUTES } from 'Routes';

const ACCEPT_VALUES = {
  privacyManager: true,
  googleTagManager: true,
};

const DECLINE_VALUES = {
  privacyManager: true,
  googleTagManager: false,
};

function CookieConsents() {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(VISIBLE_OPTIONS.BY_COOKIE_VALUE);

  const handleSettingClose = () => {
    const hasConsents = hasCookieConsents();
    if (hasConsents) {
      setVisible(VISIBLE_OPTIONS.HIDDEN);
    }
  };

  const handleAcceptClick = () => {
    initGTM();
  };

  return (
    <CookieConsent
      cookieName={COOKIE_NAME}
      visible={visible}
      location={OPTIONS.BOTTOM}
      buttonText={t('BUTTON.ACCEPT')}
      onAccept={handleAcceptClick}
      enableDeclineButton
      declineButtonText={t('BUTTON.DECLINE')}
      cookieValue={ACCEPT_VALUES}
      declineCookieValue={DECLINE_VALUES}
      cookieSecurity={true}
      disableStyles={true}
      disableButtonStyles={true}
      containerClasses='fixed flex flex-col md:flex-row gap-3 items-stretch p-3 bg-black text-white w-full'
      contentClasses='grow flex flex-col items-start gap-3'
      buttonWrapperClasses='flex flex-col-reverse md:flex-row self-stretch md:self-end gap-3'
      customButtonProps={{
        color: 'primary',
        className: 'bg-blue-600',
        variant: 'contained',
        rounded: 'true',
      }}
      customDeclineButtonProps={{
        color: 'primary',
        className: 'bg-transparent text-white',
        variant: 'outlined',
        rounded: 'true',
      }}
      ButtonComponent={Button}
    >
      <h1 className='font-bold'>{t('CONSENTS.BANNER.TITLE')}</h1>
      <p>
        {t('CONSENTS.BANNER.DESCRIPTION')}
        <Link className='ml-1 underline' to={ROUTES.PRIVACY_POLICY}>
          {t('BUTTON.PRIVACY_POLICY')}
        </Link>
        {'.'}
      </p>
      <CookiesSettings onClose={handleSettingClose} startOpen={false} />
    </CookieConsent>
  );
}

export default CookieConsents;
