import React, { useState, Fragment } from 'react';

import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Switch from '@mui/material/Switch';
import Button from '../Buttons/Button';

import { updateConsents } from 'helpers/consents';
import { initGTM } from 'helpers/gtm';
import { Cookies } from 'types/cookies';
import { Link } from 'react-router-dom';
import { ROUTES } from 'Routes';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: 'white',
  boxShadow: 24,
  p: 2,
  width: ['calc(100vw - 32px)', '75vw', '50vw', '40vw', '30vw'],
  maxHeight: 'calc(100vh - 24px)',
  overflow: 'auto',
};

interface Props {
  onClose: () => void;
  startOpen: boolean;
  initialConsents?: Cookies | null;
}

const INITIAL_VALUES = {
  privacyManager: true,
  googleTagManager: true,
};

function ConsentsSettings({ onClose, startOpen, initialConsents }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(startOpen);
  const [cookies, setCookies] = useState<Cookies>(
    initialConsents ?? INITIAL_VALUES,
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    onClose();
  };
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = evt.target;

    setCookies((cookies) => ({
      ...cookies,
      [name]: checked,
    }));
  };

  const handleAcceptAllClick = () => {
    setCookies(() => ({ privacyManager: true, googleTagManager: true }));
  };

  const handleDeclineAllClick = () => {
    setCookies(() => ({ privacyManager: true, googleTagManager: false }));
  };

  const handleSubmit = () => {
    updateConsents(cookies);
    initGTM();
    handleClose();
  };

  return (
    <Fragment>
      <Button
        onClick={handleOpen}
        className='self-stretch md:self-end bg-transparent text-white'
        rounded
        variant='outlined'
        color='primary'
      >
        {t('BUTTON.LEARN_MORE')}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box className='bg-black flex flex-col' sx={style}>
          <div
            className='flex flex-col order-first gap-2 pb-4'
            style={{ borderBottom: '1px solid #555' }}
          >
            <Typography id='modal-modal-title' variant='h5' component='h2'>
              {t('CONSENTS.MODAL.TITLE')}
            </Typography>
            <Typography id='modal-modal-description'>
              {t('CONSENTS.MODAL.DESCRIPTION')}
              <Link className='ml-1 underline' to={ROUTES.PRIVACY_POLICY}>
                {t('BUTTON.PRIVACY_POLICY')}
              </Link>
              {'.'}
            </Typography>
          </div>

          <div className='mt-4 md:order-2'>
            <Typography
              className='font-bold'
              variant='subtitle1'
              component='h3'
              sx={{ lineHeight: 1 }}
            >
              {t('CONSENTS.MODAL.COOKIES.NECESSARY.TITLE')}
            </Typography>
            <Typography variant='body2'>
              {t('CONSENTS.MODAL.COOKIES.NECESSARY.DESCRIPTION')}
            </Typography>
            <div className='flex gap-3 mt-3'>
              <Switch
                checked={cookies.privacyManager}
                name='privacyManager'
                disabled
                onChange={handleChange}
                inputProps={{ 'aria-label': 'privacy-manager-switch' }}
              />
              <div className='flex flex-col'>
                <div className='flex flex-wrap sm:gap-1'>
                  <Typography
                    className='font-bold'
                    variant='subtitle1'
                    component='p'
                    sx={{ lineHeight: 1 }}
                  >
                    {t('CONSENTS.MODAL.COOKIES.NECESSARY.APPS.OWN.TITLE')}
                  </Typography>
                  <Typography variant='caption' component='small'>{`(${t(
                    'CONSENTS.MODAL.COOKIES.NECESSARY.APPS.OWN.REQUIRED',
                  )})`}</Typography>
                </div>
                <Typography
                  variant='subtitle2'
                  component='p'
                  sx={{ lineHeight: 1 }}
                >
                  {t('CONSENTS.MODAL.COOKIES.NECESSARY.APPS.OWN.DESCRIPTION')}
                </Typography>
              </div>
            </div>
          </div>

          <div
            className='pb-4 md:order-3'
            style={{ borderBottom: '1px solid #555' }}
          >
            <div className='mt-4'>
              <Typography
                className='font-bold'
                variant='subtitle1'
                component='h3'
                sx={{ lineHeight: 1 }}
              >
                {t('CONSENTS.MODAL.COOKIES.PERFORMANCE.TITLE')}
              </Typography>
              <Typography variant='body2'>
                {t('CONSENTS.MODAL.COOKIES.PERFORMANCE.DESCRIPTION')}
              </Typography>
              <div className='flex gap-3 mt-3'>
                <Switch
                  checked={cookies.googleTagManager}
                  name='googleTagManager'
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'google-tag-manager-switch' }}
                />
                <div className='flex flex-col'>
                  <Typography
                    className='font-bold'
                    variant='subtitle1'
                    component='p'
                  >
                    {t(
                      'CONSENTS.MODAL.COOKIES.PERFORMANCE.APPS.GOOGLE_TAG_MANAGER.TITLE',
                    )}
                  </Typography>
                  <Typography
                    variant='subtitle2'
                    component='p'
                    sx={{ lineHeight: 1 }}
                  >
                    {t(
                      'CONSENTS.MODAL.COOKIES.PERFORMANCE.APPS.GOOGLE_TAG_MANAGER.DESCRIPTION',
                    )}
                  </Typography>
                </div>
              </div>
              <div className='flex gap-3 mt-3'>
                <div className='mt-2 text-center'>
                  <Typography
                    variant='subtitle2'
                    component='p'
                    sx={{ lineHeight: 1, width: 58 }}
                  >
                    {t('Opt-out')}
                  </Typography>
                  <a
                    className='text-blue-500 text-sm'
                    href='https://www.youtube.com/history_controls'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {t('BUTTON.HERE')}
                  </a>
                </div>

                <div className='flex flex-col'>
                  <Typography
                    className='font-bold'
                    variant='subtitle1'
                    component='p'
                  >
                    {t('CONSENTS.MODAL.COOKIES.PERFORMANCE.APPS.YOUTUBE.TITLE')}
                  </Typography>
                  <Typography
                    variant='subtitle2'
                    component='p'
                    sx={{ lineHeight: 1 }}
                  >
                    {t(
                      'CONSENTS.MODAL.COOKIES.PERFORMANCE.APPS.YOUTUBE.DESCRIPTION',
                    )}
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div className='flex flex-col-reverse sm:flex-row justify-evenly gap-3 mt-4 md:order-1'>
            <Button
              type='submit'
              rounded
              color='primary'
              className='bg-blue-600'
              variant='contained'
              onClick={handleAcceptAllClick}
            >
              {t('BUTTON.ACCEPT_ALL')}
            </Button>
            <Button
              color='primary'
              rounded
              className='bg-transparent text-white'
              variant='outlined'
              onClick={handleDeclineAllClick}
            >
              {t('BUTTON.DECLINE_ALL')}
            </Button>
          </div>

          <div className='flex flex-col order-last gap-2 pt-4'>
            <Button
              rounded
              color='primary'
              className='bg-blue-600'
              variant='contained'
              onClick={handleSubmit}
            >
              {t('BUTTON.SAVE')}
            </Button>
          </div>
        </Box>
      </Modal>
    </Fragment>
  );
}

export default ConsentsSettings;
